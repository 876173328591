import '../css/Paginator.css'
import scrollToTop from '../funcs/scrollToTop'

function Paginator({pageInfo}) {
  const { page, setPage, totalPages } = pageInfo
  const pageFirst = 1
  const pagePrevious = page === 1 ? 1 : (page - 1)
  const pageNext = page + 1
  const pageLast = totalPages
  return (
    <nav id="pagination-list" className="d-flex justify-content-center p-2">
      <ul className="pagination">
        <FirstPage num={pageFirst} page={page} setPage={setPage}/>
        <PreviousPage num={pagePrevious} page={page} setPage={setPage}/>
        <CurrentPage num={page}/>
        <NextPage num={pageNext} page={page} setPage={setPage} total={totalPages}/>
        <LastPage num={pageLast} page={page} setPage={setPage} total={totalPages}/>
      </ul>
    </nav>
  )
}

function FirstPage({num, page, setPage}) {
  const handleClick = () => {
    if(page > 1) {
      setPage(1)
      scrollToTop()
    }
  }
  return (
    <li id="pag-first" className="page-item" disabled={page <= num} onClick={handleClick}>
      <span className="page-link"  aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
      </span>
    </li>
  )
}

function PreviousPage({num, page, setPage}) {
  const handleClick = () => {
    if(page > 1) {
      setPage(page - 1)
      scrollToTop()
    }
  }
  const isDisabled = page <= num
  const display = isDisabled ? '...' : num
  return (
    <li id="pag-previous" className="page-item" disabled={isDisabled} onClick={handleClick}>
      <span className="page-link" >{display}</span>
    </li>
  )
}

function CurrentPage({num}) {
  return (
    <li className="page-item active">
      <span className="page-link" >{num}</span>
    </li>
  )
}

function NextPage({num, page, setPage, total}) {
  const handleClick = () => {
    if(page < total) {
      setPage(page + 1)
      scrollToTop()
    }
  }
  const isDisabled = page >= total
  const display = isDisabled ? '...' : num
  return (
    <li id="pag-next" className="page-item" disabled={isDisabled} onClick={handleClick}>
      <span className="page-link" >{display}</span>
    </li>
  )
}

function LastPage({num, page, setPage, total}) {
  const handleClick = () => {
    if(page < total) {
      setPage(total)
      scrollToTop()
    }
  }
  return (
    <li id="pag-last" className="page-item" disabled={page >= total} onClick={handleClick}>
      <span className="page-link"  aria-label="Next">
        <span aria-hidden="true">&raquo;</span>
      </span>
    </li>
  )
}

export default Paginator