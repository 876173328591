import Modal from 'react-bootstrap/Modal';

function BuildInfo({ show, handleClose, buildDate }) {
  const dateObj = new Date(buildDate)
 
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Data Build Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <dl className="row">
          <dt className="col-3 text-center"><i className="fa-solid fa-calendar-days"></i></dt>
          <dd className="col-9">{getNiceDateString(dateObj)}</dd>
          <dt className="col-3 text-center"><i className="fa-solid fa-clock"></i></dt>
          <dd className="col-9">{getTimeString(dateObj)}</dd>
        </dl>
      </Modal.Body>
    </Modal>
  )
}

function getNiceDateString(d) {
  return ("0" + d.getDate()).slice(-2) + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    d.getFullYear();
}

function getTimeString(d) {
  return ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
}

export default BuildInfo