import filterGames from "./filterGames"

export default function filterLists( lists, options, games) {
  const filteredGames = filterGames(games, options)

  // if(filteredGames.length === games.length) return lists
  const filteredGamesIds = filteredGames.map(g => g.id)

  const newLists = {}
  for (const prop in lists) {
    newLists[prop] = lists[prop]
    const { gameIds } = lists[prop]
    const validGameIds = gameIds.filter(id => filteredGamesIds.includes(id))
    newLists[prop].gameIds = validGameIds
  }
  return newLists
}