export default function filterGames( games, options) {
  const { categories, years, singlePlayer, coop, multiplayer, crossgen, optimised, fps, display, audio } = options

  let filteredGames = games

  if(categories.length > 0) filteredGames = filterCategories(filteredGames, categories)

  if(years.length > 0) filteredGames = filterYears(filteredGames, years)

  if(singlePlayer.length > 0) filteredGames = filterSinglePlayer(filteredGames)

  if(coop.length > 0) filteredGames = filterForKeys(filteredGames, coop)

  if(multiplayer.length > 0) filteredGames = filterForKeys(filteredGames, multiplayer)

  if(crossgen.length > 0) filteredGames = filterForKeys(filteredGames, crossgen)

  if(optimised > 0) filteredGames = filterForKeys(filteredGames, optimised)

  if(fps.length > 0) filteredGames = filterForKeys(filteredGames, fps)

  if(display.length > 0) filteredGames = filterForKeys(filteredGames, display)

  if(audio.length > 0) filteredGames = filterForKeys(filteredGames, audio)

  return filteredGames
}


function filterCategories(filteredGames, categories) {
  return filteredGames.filter(game => {
   
    const categoriesGame = getGameCategories(game.categories)
    let isOk = false
    for (const category of categories) {
      if(categoriesGame.includes(category.name)) {
        isOk = true
        break
      }
    }
    return isOk
  })
}

function filterYears(filteredGames, years) {
  return filteredGames.filter(game => {
   
    const { releaseYear } = game
    let isOk = false
    for (const y of years) {
      const [min, max] = y.range
      if(releaseYear >= min && releaseYear <= max) {
        isOk = true
        break
      }
    }
    return isOk
  })
}

function filterSinglePlayer(filteredGames) {
  return filteredGames.filter(game => {
    const { capabilities } = game
    return capabilities && capabilities['SinglePlayer']
  })
}

function filterForKeys(filteredGames, arr) {
  return filteredGames.filter(game => {
    const { capabilities } = game
    if(!capabilities) return false
    const keys = Object.keys(capabilities)
    let isOk = false
    for (const e of arr) {
      if(keys.includes(e.name)) {
        isOk = true
        break
      }
    }
    return isOk
  })
}

function getGameCategories(categories) {
  const { main, all } = categories

  const uniqueCategories = []
  if(main && !uniqueCategories.includes(main)) uniqueCategories.push(main)
  if(Array.isArray(all)) {
    all.forEach(c => {
      if(!uniqueCategories.includes(c)) uniqueCategories.push(c)
    })
  }

  return uniqueCategories
}