export default function getDefaultUserFilters() {
  return {
    categories: [],
    years: [],
    singlePlayer: [],
    coop: [],
    multiplayer: [],
    crossgen: [],
    optimised: [],
    fps: [],
    display: [],
    audio: []
  }
}