import '../css/GridItem.css'
import { useNavigate } from "react-router-dom";
import defaultImage from '../images/default.jpg'
import studioImage from '../images/studio.jpg'
import developerImage from '../images/developer.jpg'
import ScoreBadge from './ScoreBadge';

function GridItem({item}) {
  const itemLink = item.id ? <GameLink id={item.id}/> : ''
  return (
    <div className='grid-item position-relative placeholder'>
      <Image item={item}/>
      <Scores item={item}/>
      { itemLink }
    </div>
  )
}

function Image({item}) {
  const showFilmName = true

  const navigate = useNavigate();
  const handleClick = () => navigate(item.link);

  let src
  if(!item.image) {
    src = defaultImage
    if(item.type === 'd') src = developerImage
    if(item.type === 'p') src = studioImage
  }
  if(item.image) src = item.image.uri.concat('?w=200')
  

  const titleText = !item.image || showFilmName ? <ImageTitle title={item.title}/> : ''
  return (
    <div className="position-relative">
      <img className="img-resp" src={src} alt="game poster" onClick={handleClick}/>
      {titleText}
    </div>
  )
}

function ImageTitle({ title}) {
  const style = {top: '35%'}
  return (
    <p className='image-text position-absolute w-100 text-wrap text-center' style={style}>
      {title}
    </p>
  )
}

function Scores({item}) {
  const hasSteamDb = item.ratings && item.ratings.steam && item.ratings.steam.ratingSteamDb && (item.ratings.steam.ratingSteamDb !== -1)
  
  let scoreSteamDb
  if(hasSteamDb) {
    const badge = <ScoreBadge score={item.ratings.steam.ratingSteamDb} text={item.ratings.steam.ratingSteamDb.toFixed(2) + '%'}/>
    scoreSteamDb = <a target="_blank" rel="noreferrer" href={item.ratings.steam.url}>{badge}</a>
  } else {
    scoreSteamDb = ''
  }
  const altScoreHtml = ''
  return (
    <div className="scores d-flex justify-content-around position-absolute bottom-0 p-1">
      {scoreSteamDb}
      {altScoreHtml}
    </div>
  )
}


function GameLink({id}) {
  const url = `https://www.xbox.com/en-gb/games/store/game/${id}`
  // const icon = <i className="fa-brands fa-xbox"></i>
  const badge = <i className="fa-brands fa-xbox icon-green"></i>//<span className="badge bg-light text-dark">{icon}</span>
  const link = <a target="_blank" rel="noreferrer" href={url}>{badge}</a>
  return (
    <div className='station d-flex justify-content-end position-absolute top-0 p-1'>
      <span>{link}</span>
    </div>
  )
}

export default GridItem