import React, { useState } from 'react'
import { useHref, useOutletContext } from 'react-router-dom'
import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';
import PageHeader from '../comps/PageHeader';

function PublisherPage() {
  const [ context ] = useOutletContext();
  const { games } = context
  const currentHref = useHref()
  const pubName = getName(currentHref)
  const pubGames = []

  for (const game of games) {
    if(pubName === game.publisher) pubGames.push(game)
  }

  let content
  if(pubGames.length === 0) {
    content = <NoPublisher pubName={pubName}/>
  } else {
    content = <YesPublisher pubName={pubName} pubGames={pubGames}/>
  }

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      {content}
    </div>
  )
}

function NoPublisher({pubName}) {
  return <div>Could not find a publisher called '{pubName}'</div>
}

function YesPublisher({pubName, pubGames}) {
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, pubGames).map(g => {
    g.link = `/g/${g.id}`
    return g
  })
  const totalPages = Math.ceil(pubGames.length/itemsPerPage)
  const pageInfo = {page, setPage, totalPages}

  const paginator = pubGames.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={pubGames.length}/> : ''

  return (
    <>
      <PageHeader title={pubName} description='Game Publisher'/>
      <Grid items={gridItems} pageInfo={pageInfo}/>
      { paginator }
    </>
  )
}


function getName(str) {
  const startIndex = str.indexOf('/', 1)
  if(startIndex < 0) return null
  const slice = str.slice(startIndex + 1)
  return decodeURI(slice)
}

export default PublisherPage