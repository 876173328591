import React, { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';
import PageHeader from '../comps/PageHeader';

function Publishers() {
  const [ context ] = useOutletContext();
  const { games } = context
  let publishers = []
  for (const game of games) {
    const addToPublishers = !publishers.find(d => d === game.publisher)
    if(addToPublishers) publishers.push(game.publisher)
  }
  publishers = publishers
  .filter(d => d.length > 0)
  .map(d => {
    return { title: d, link: `/p/${d}`, type: 'p'}
  })
  
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, publishers)
  const totalPages = Math.ceil(publishers.length/itemsPerPage)
  const pageInfo = {page, setPage, totalPages}


  const paginator = publishers.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={publishers.length}/> : ''

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      <PageHeader title='Publishers'/>
      <Grid items={gridItems} pageInfo={pageInfo}/>
      { paginator }
    </div>
  )
}

export default Publishers