import React, { useState, useEffect } from 'react'
import './css/App.css';
import Loader from './comps/misc/Loader';
import Fail from './comps/misc/Fail';
import fetchData from './funcs/fetchData'
import { Outlet } from 'react-router-dom'

function App({ url }) {
  let contents = null
  const [data, setData] = useState({ status: 'fetching', d: false })
  const [windowSize, setWindowSize] = useState(getWindowSize())
  if(data.status === 'fetching') contents = <Loader />
  if(data.status === 'failure') contents = <Fail />

  // EFFECTS
  // get data
  useEffect(() => { fetchData(url, setData) }, [url])

  // user screen dimensions
  useEffect(() => {
    const handleWindowResize = () => setWindowSize(getWindowSize())
    window.addEventListener('resize', handleWindowResize); // the effect
    return () => window.removeEventListener('resize', handleWindowResize); // Specify how to clean up after this effect
  }, []);


  return (
    <div id="app" className="app flex-container min-vh-100">
      {contents || <Outlet context={[data, windowSize]}/>}
    </div>
  );
}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default App;