import getPercentage from "./funcGetPercentage"
import ScoreBadge from "../ScoreBadge"

export default function ReviewDetails({data, wikiUrl}) {

  let content
  if(data.length === 0) content = <p>No Data Available</p>
  if(data.length > 0) {
    const agg = data.map((d, i) => {
      const {name, data } = d
      const lines = data.map((l,i) => {
        l.name = l.name || 'Unknown'
        const percentageScore = getPercentage(l.name)
        const badge = !percentageScore ? <span key={l + i} className="badge bg-secondary m-1">{l.name}</span> : <ScoreBadge score={percentageScore} text={l.name}/>
        const url = l.url ? l.url[0] : wikiUrl
        return <a key={l + i} target="_blank" rel="noreferrer" href={url}>{badge}</a>
      })
      const content = lines.length > 0 ? lines : <span className="badge bg-warning m-1">Unknown</span>
      return (
        <dl key={name + i} className="row m-1">
          <dt className="col-sm-3">{name}</dt>
          <dd className="col-sm-9">{content}</dd>
        </dl>
      )
    })
    content = <>{agg}</>
  }
  return (
    <div>
      { content }
    </div>
  )
}
