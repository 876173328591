export default function getAllYears() {
  const today = new Date()
  const year = today.getFullYear()
  return [
    {
      name: '_Preview',
      range: [year + 1, 9999]
    },
    {
      name: `This Year`,
      range: [year, year]
    },
    {
      name: `Last Year`,
      range: [year - 1, year - 1]
    },
    {
      name: `_${year - 2} - ${year - 5}`,
      range: [ year - 5, year - 2]
    },
    {
      name: `_${year - 6} - ${year - 10}`,
      range: [year - 10, year - 6]
    },
    {
      name: `_${year - 11} - ${year - 15}`,
      range: [year - 15, year - 11]
    },
    {
      name: `_${year - 16} - ${year - 20}`,
      range: [year - 20, year - 16]
    },
    {
      name: `_Before ${year - 21}`,
      range: [0, year - 21]
    }

  ]
}