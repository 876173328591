import Offcanvas from 'react-bootstrap/Offcanvas';
import MultiSelect from './misc/MultiSelect';

function FiltersSelector({ filtersInfo, show, handleClose }) {
  const { userFilters, setUserFilters, options } = filtersInfo
  const { categories, years, singlePlayer, coop, multiplayer, crossgen, optimised, fps, display, audio } = options

  return (
    <Offcanvas placement='end' show={show} onHide={handleClose} backdrop={false}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Filter Games</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <MultiSelect label='Categories:' propName='categories' current={userFilters.categories} setCurrent={setUserFilters} choices={categories}/>
        <hr/>
        <MultiSelect label='Years:' propName='years' current={userFilters.years} setCurrent={setUserFilters} choices={years}/>
        <hr/>
        <MultiSelect label='Single Player:' propName='singlePlayer' current={userFilters.singlePlayer} setCurrent={setUserFilters} choices={singlePlayer}/>
        <hr/>
        <MultiSelect label='Co-op:' propName='coop' current={userFilters.coop} setCurrent={setUserFilters} choices={coop}/>
        <hr/>
        <MultiSelect label='Multiplayer:' propName='multiplayer' current={userFilters.multiplayer} setCurrent={setUserFilters} choices={multiplayer}/>
        <hr/>
        <MultiSelect label='Cross-Platform:' propName='crossgen' current={userFilters.crossgen} setCurrent={setUserFilters} choices={crossgen}/>
        <hr/>
        <MultiSelect label='Optimised:' propName='optimised' current={userFilters.optimised} setCurrent={setUserFilters} choices={optimised}/>
        <hr/>
        <MultiSelect label='FPS:' propName='fps' current={userFilters.fps} setCurrent={setUserFilters} choices={fps}/>
        <hr/>
        <MultiSelect label='Display:' propName='display' current={userFilters.display} setCurrent={setUserFilters} choices={display}/>
        <hr/>
        <MultiSelect label='Audio:' propName='audio' current={userFilters.audio} setCurrent={setUserFilters} choices={audio}/>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

// {JSON.stringify(userFilters)}

export default FiltersSelector