import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/colours.css'
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'

import AppMain from './AppMain';
import About from './pages/About'
import Contact from './pages/Contact'
import Search from './pages/Search';
import Library from './pages/Library'
import GamePage from './pages/GamePage'
import Developers from './pages/Developers';
import DeveloperPage from './pages/DeveloperPage';
import Publishers from './pages/Publishers';
import PublisherPage from './pages/PublisherPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
const dataUrl = 'https://gist.githubusercontent.com/wasim-build/344d5d7ee4980cddb22189fa00bbbf52/raw'
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App url={dataUrl}/>}>
          <Route path='/' element={<AppMain/>}>
            <Route path='/' element={<Library/>} />
            <Route path='/search' element={<Search/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/g/*' element={<GamePage/>}/>
            <Route path='/developers' element={<Developers/>}/>
            <Route path='/d/*' element={<DeveloperPage/>}/>
            <Route path='/publishers' element={<Publishers/>}/>
            <Route path='/p/*' element={<PublisherPage/>}/>
            <Route path='*' element={<div>Oops!!</div>} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);



reportWebVitals();