import '../css/Grid.css'
import React, { useState } from 'react'
import GridItem from './GridItem';
import scrollToTop from '../funcs/scrollToTop';

function Grid({ items, pageInfo }) {
  const { page, setPage, totalPages } = pageInfo

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  
  const minSwipeDistance = 50; // the required distance between touchStart and touchEnd to be detected as a swipe

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe && page > 1) {
      setPage(page - 1)
      scrollToTop()
    }
    if (isLeftSwipe && page < totalPages) {
      setPage(page + 1)
      scrollToTop()
    }
  }

  const itemsHtml = items.map((e, i) => {
    return <GridItem key={i} item={e}/>
  })
  return (
    <div id="grid" className="fade-in container mt-3 mb-5" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <div className="grid-wrapper">{itemsHtml}</div>
    </div>
  )
}



export default Grid
