import PageHeader from '../comps/PageHeader';
import PageInfoItem from '../comps/PageInfoItem';

function About() {
  return (
    <div id="about" className='xgp-bg-img flex-container flex-content'>
      <PageHeader title='About'/>
      <div className='fade-in container flex-container flex-content'>
        <div className="flex-container flex-content  justify-content-around">
          <PageInfoItem title='What' body={<What/>}/>
          <PageInfoItem title='Why' body={<Why/>}/>
        </div>
      </div>
    </div>
  )
}

function What() {
  return (
    <div>
      <p>A web application that provides extensive information about games available and coming to Microsoft's Game Pass.</p>
    </div>
  )
}

function Why() {
  return (
    <div>
      <p>This web application exists in order to provide visitors with more information about the games available on Microsoft's Game Pass (XGP).</p>
      <p>The main difference between it and Microsoft's official Game Pass <a href="https://www.xbox.com/en-GB/xbox-game-pass/games" target="_blank" rel="noreferrer">web page</a> is that, where possible, it <b>collates review data</b> for each game available or shortly coming to XGP. It is hoped that this additional information will help users more easily choose from the hundreds of games that are currently available in the XGP library.</p>
    </div>
  )
}

export default About;