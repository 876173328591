export default function getAllCapabilities(games) {
  const capabilityTypes = {}
  games.forEach(game => {
    const gameCapabilities = game.capabilities
    if(isObject(gameCapabilities)) {
      for (const prop in gameCapabilities) {
        if(!capabilityTypes[prop]) capabilityTypes[prop] = []

        const capability = gameCapabilities[prop]
        if(!capabilityTypes[prop].includes(capability)) capabilityTypes[prop].push(capability)
      }
    }
  })
  return capabilityTypes
}


function isObject(x) {
  return typeof x === 'object' && !Array.isArray(x) && x !== null
}