import { useOutletContext } from 'react-router-dom'
import LibraryButtons from '../comps/LibraryButtons';
import LibraryList from '../comps/LibraryList';
import PageHeader from '../comps/PageHeader';

function Library() {
  const [ context ] = useOutletContext();
  const { lists, listName, setListName, page, setPage, windowSize, games, userFilters, setUserFilters, options, buildDate } = context

  const listsInfo = { listName, setListName, lists}
  const pageInfo = { page, setPage, windowSize }
  const filtersInfo = {userFilters, setUserFilters, options}

  const list = lists[listName]
  list.games = list.gameIds.map( id => games.find(g => g.id === id))

  const { title, description } = list

  // const filterUpdate = userFiltersActive(userFilters) ? <div className='w-100 text-center bg-xb-green'>Filters Are Active</div> : ''
  const filterUpdate = userFiltersActive(userFilters) ? <div className='w-100 text-center bg-xb-green text-white'>{list.games.length} Games (Filters Are Active)</div> : <div className='w-100 text-center bg-xb-green text-white'>{list.games.length} Games</div>

  return (
    <div id="library" className='flex-container flex-content'>
      <PageHeader title={title} description={description}/>
      <LibraryButtons listsInfo={listsInfo} filtersInfo={filtersInfo} buildDate={buildDate}/>
      {filterUpdate}
      <LibraryList list={list} pageInfo={pageInfo}/>
      
    </div>
  )
}

function userFiltersActive(userFilters) {
  for (const prop in userFilters) {
    if(userFilters[prop].length > 0) return true
  }
  return false
}

export default Library;