import PageInfoItem from "../PageInfoItem"
import SteamDetails from "./SteamDetails"
import AggregateDetails from "./AggregateDetails"
import ReviewDetails from "./ReviewDetails"

export default function AllDetails({steam, wiki, wikiUrl}) {
  return (
    <div className="mh-68 flex-container flex-content  justify-content-around">
      <PageInfoItem title='Steam' body={<SteamDetails data={steam}/>}/>
      <hr/>
      <PageInfoItem title='Aggregate' body={<AggregateDetails data={wiki.aggregators} wikiUrl={wikiUrl}/>}/>
      <hr/>
      <PageInfoItem title='Reviews' body={<ReviewDetails data={wiki.reviews} wikiUrl={wikiUrl}/>}/>
    </div>
  )
}