import React, { useState } from 'react';
import { useHref, useOutletContext } from 'react-router-dom'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../css/GamePage.css'

import GameAbout from '../comps/GamePageComps/GameAbout';
import GameDetails from '../comps/GamePageComps/GameDetails';
import RatingsTabs from '../comps/GamePageComps/RatingsTabs'

import scrollToTop from '../funcs/scrollToTop';
import PageHeader from '../comps/PageHeader';

function GamePage() {
  scrollToTop()
  const [ context ] = useOutletContext();
  const { lists, games } = context
  const currentHref = useHref()
  const id = getId(currentHref)
  const game = games.find(g => g.id === id)
  if(!game) return <div>Could not find game with an id of '{id}'</div>
  const { title } = game
  game.status = getXgpStatus(game.id, lists)

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      <PageHeader title={title}/>

      <div className='gp-fade-in game-tabs container flex-container flex-content'>
        <GamePageTabs game={game}/>
      </div>

    </div>
  )
}


function GamePageTabs({ game }) {
  const { descriptionLong, image, ratings } = game
  const { wiki, steam } = ratings
  const [key, setKey] = useState('about');

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      fill
    >
      <Tab eventKey="about" title="About">
        <GameAbout descriptionLong={descriptionLong} image={image}/>
      </Tab>
      <Tab eventKey="overview" title="Overview">
        <GameDetails game={game}/>
      </Tab>
      <Tab eventKey="ratings" title="Ratings">
        <RatingsTabs steam={steam} wiki={wiki}/>
      </Tab>
    </Tabs>
  );
}

function getId(str) {
  const startIndex = str.lastIndexOf('/')
  if(startIndex < 0) return null
  const slice = str.slice(startIndex + 1)
  return slice
}

function getXgpStatus(id, lists) {
  const { CONSOLE_ALL, CONSOLE_RECENTLY_ADDED, CONSOLE_LEAVING_SOON, CONSOLE_COMING_SOON } = lists
  const available = CONSOLE_ALL.gameIds.includes(id)
  const recent = CONSOLE_RECENTLY_ADDED.gameIds.includes(id)
  const leaving = CONSOLE_LEAVING_SOON.gameIds.includes(id)
  const coming = CONSOLE_COMING_SOON.gameIds.includes(id)
  return { available, recent, leaving, coming }
}

export default GamePage