import Grid from './Grid'
import Paginator from './Paginator'
import getPageItems from '../funcs/getPageItems'

function LibraryList({list, pageInfo}) {
  const { games } = list

  const itemsPerPage = 18
  const gridItems = getPageItems(pageInfo.page, itemsPerPage, games).map(g => {
    g.link = `/g/${g.id}`
    return g
  })
  pageInfo.totalPages = Math.ceil(games.length/itemsPerPage)

  const paginator = games.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={games.length}/> : ''

  return (
    <div className="xgp-bg-img flex-container flex-content">
      <div className='flex-content'>
        <Grid items={gridItems} pageInfo={pageInfo}/>
        { paginator }
      </div>
    </div>
  )
}

export default LibraryList;