import { useNavigate } from 'react-router-dom'

export default function GameDetails({ game }) {
  const { status, releaseYear, developer, publisher, categories, contentRating, maxInstallSize, availableOn, capabilities } = game
  const navigate = useNavigate();
  const categoriesAll = categories.all ? categories.all.join(', ') : categories.main
  const handleDevClick = () => {
    if(developer.length > 0) navigate(`/d/${developer}`);
  }
  const handlePubClick = () => {
    if(publisher.length > 0) navigate(`/p/${publisher}`);
  }

  const gameStatus = status.available ? 'fa-solid fa-circle-check' : 'fa-solid fa-circle-xmark'
  const recent = status.recent ? <span className="badge m-1">Recently Added</span> : ''
  const coming = status.coming ? <span className="badge m-1">Coming Soon</span> : ''
  const leaving = status.leaving ? <span className="badge m-1">Leaving Soon</span> : ''
  const platforms = availableOn.map((p, i) => <span key={i} className='badge bg-xb-green m-1'>{p}</span>)
  const gameCapabilities = ( () => {
    const arr = []
    for (const prop in capabilities) {
      arr.push(capabilities[prop])
    }
    return arr.map((e, i) => <span key={i} className='badge bg-xb-green m-1'>{e}</span>)
  })()
  const year = releaseYear > 2070 ? 'Unknown' : releaseYear

  return (
    <div className='container'>
      <dl className="row">
        <dt className="col-sm-3">Game Pass Status:</dt>
        <dd className="col-sm-9"><i className={gameStatus}></i>{recent}{coming}{leaving}</dd>
        <dt className="col-sm-3">Release Year:</dt>
        <dd className="col-sm-9">{year}</dd>
        <dt className="col-sm-3">Developer:</dt>
        <dd className="col-sm-9" onClick={handleDevClick}>{(developer.length > 0) ? developer : 'N/A'}</dd>
        <dt className="col-sm-3">Publisher:</dt>
        <dd className="col-sm-9" onClick={handlePubClick}>{(publisher.length > 0) ? publisher : 'N/A'}</dd>
        <dt className="col-sm-3">Categories:</dt>
        <dd className="col-sm-9">{categoriesAll}</dd>
        <dt className="col-sm-3">Content Rating:</dt>
        <dd className="col-sm-9">{contentRating.description}</dd>
        <dt className="col-sm-3">Platforms:</dt>
        <dd className="col-sm-9">{platforms}</dd>
        <dt className="col-sm-3">Capabilities:</dt>
        <dd className="col-sm-9">{gameCapabilities}</dd>
        <dt className="col-sm-3">Install Size (Max):</dt>
        <dd className="col-sm-9">{(maxInstallSize/1e9).toFixed(2)} GB</dd>
      </dl>
    </div>
  )
}