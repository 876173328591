import React, { useState } from 'react'
import '../css/Developers.css'
import { useOutletContext } from 'react-router-dom'
import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';
import PageHeader from '../comps/PageHeader';

function Developers() {
  const [ context ] = useOutletContext();
  const { games } = context
  let developers = []
  for (const game of games) {
    const addToDevelopers = !developers.find(d => d === game.developer)
    if(addToDevelopers) developers.push(game.developer)
  }
  developers = developers
  .filter(d => d.length > 0)
  .map(d => {
    return { title: d, link: `/d/${d}`, type: 'd'}
  })
  
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, developers)
  const totalPages = Math.ceil(developers.length/itemsPerPage)
  const pageInfo = {page, setPage, totalPages}

  const paginator = developers.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={developers.length}/> : ''

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      <PageHeader title='Developers'/>
      <Grid items={gridItems} pageInfo={pageInfo}/>
      { paginator }
    </div>
  )
}

export default Developers