import React, { useState, useEffect } from 'react'
import { Outlet, useOutletContext, Link, useHref } from 'react-router-dom'
import AppMainNav from './comps/AppMainNav';
import getAllCategories from './funcs/filterOptions/getAllCategories';
import getAllCapabilities from './funcs/filterOptions/getAllCapabilities';
import getDefaultUserFilters from './funcs/getDefaultUserFilters';
import getAllYears from './funcs/filterOptions/getAllYears';
import getSinglePlayer from './funcs/filterOptions/getSinglePlayer';
import getAllCoops from './funcs/filterOptions/getAllCoops';
import getAllMultiplayers from './funcs/filterOptions/getAllMultiplayers';
import getAllGrossGen from './funcs/filterOptions/getAllCrossGens';
import getAllOptimised from './funcs/filterOptions/getAllOptimised';
import getAllFps from './funcs/filterOptions/getAllFps';
import getAllDisplay from './funcs/filterOptions/getAllDisplay';
import getAllAudio from './funcs/filterOptions/getAllAudio'

import filterLists from './funcs/filterLists';

function Main() {
  // this will be the component with all the logic
  const [ data, windowSize ] = useOutletContext();
  const { games, buildDate } = data.d
  const [listName, setListName ] = useState('CONSOLE_ALL')
 
  const [page, setPage] = useState(1)

  const [lists, setLists] = useState(JSON.parse(JSON.stringify(data.d.lists)))
  const [userFilters, setUserFilters] = useState(getDefaultUserFilters)

  const options = {
    capabilities: getAllCapabilities(games),
    categories: getAllCategories(games),
    years: getAllYears(),
    singlePlayer: getSinglePlayer(),
    coop: getAllCoops(),
    multiplayer: getAllMultiplayers(),
    crossgen: getAllGrossGen(),
    optimised: getAllOptimised(),
    fps: getAllFps(),
    display: getAllDisplay(),
    audio: getAllAudio()
  }

  useEffect(() => { setPage(1) }, [listName]) // if list is changed goes to first page

  // filter data
  useEffect(() => {
      const lists = JSON.parse(JSON.stringify(data.d.lists))
      const filteredLists = filterLists(lists, userFilters, games)
      setLists(filteredLists)
  }, [ data.d.lists, userFilters, games])
  
  const context = {
    lists,
    listName,
    setListName,
    page,
    setPage,
    windowSize,
    games,
    options,
    buildDate,
    userFilters,
    setUserFilters
  }

  return (
    <main id="app-main "className='flex-container flex-content'>
      <AppMainNav/>
      <Outlet context={[context]}/>
    </main>
  )
}

function getYearsMinMax(games) {
  let min, max;
  games.forEach((g, i) => {
    if(!min && !max) {
      min = g.releaseYear
      max = g.releaseYear
    }
    if(g.releaseYear < min) min = g.releaseYear
    if(g.releaseYear > max && g.releaseYear < 2024) max = g.releaseYear
  })
  return [min, max]
}

export default Main;