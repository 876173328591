export default function getPercentage(str) {
  const dividerIndex = str.indexOf('/')
  if(dividerIndex < 0) return null
  const rightOfDivider = str.slice(dividerIndex + 1)
  const denominator = parseFloat(rightOfDivider)
  // get numerator
  const reverseStr = str.split('').reverse().join('')
  const reverseDividerIndex = reverseStr.indexOf('/')
  const leftOfDivider = reverseStr.slice(reverseDividerIndex + 1)
  const whiteSpaceIndex = leftOfDivider.indexOf(' ')
  const reverseNumeratorStr = whiteSpaceIndex !== -1 ? leftOfDivider.slice(0, whiteSpaceIndex) : leftOfDivider
  const numeratorStr = reverseNumeratorStr.split('').reverse().join('')
  const numerator = parseFloat(numeratorStr)

  const areBothNumbers = !isNaN(numerator) && !isNaN(denominator)
  if(!areBothNumbers) return null
  const percentage = (numerator/denominator) * 100
  return percentage
}