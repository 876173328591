export default function getAllCategories(games) {
  const categories = []
  games.forEach(game => {
    const { main, all } = game.categories
    if(main && !categories.includes(main)) categories.push(main)
    if(Array.isArray(all)) {
      all.forEach(c => {
        if(!categories.includes(c)) categories.push(c)
      })
    }
  })
  return categories.map(category => {
    return { name: category }
  })
}