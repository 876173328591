import React, { useState } from 'react'
import { useHref, useOutletContext } from 'react-router-dom'
import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';
import PageHeader from '../comps/PageHeader';

function DeveloperPage() {
  const [ context ] = useOutletContext();
  const { games } = context
  const currentHref = useHref()
  const devName = getName(currentHref)
  const devGames = []

  for (const game of games) {
    if(devName === game.developer) devGames.push(game)
  }

  let content
  if(devGames.length === 0) {
    content = <NoDeveloper devName={devName}/>
  } else {
    content = <YesDeveloper devName={devName} devGames={devGames}/>
  }

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      {content}
    </div>
  )
}

function NoDeveloper({devName}) {
  return <div>Could not find a developer called '{devName}'</div>
}

function YesDeveloper({devName, devGames}) {
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, devGames).map(g => {
    g.link = `/g/${g.id}`
    return g
  })
  const totalPages = Math.ceil(devGames.length/itemsPerPage)
  const pageInfo = {page, setPage, totalPages}

  const paginator = devGames.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={devGames.length}/> : ''

  return (
    <>
      <PageHeader title={devName} description='Game Developer'/>
      <Grid items={gridItems} pageInfo={pageInfo}/>
      { paginator }
    </>
  )
}


function getName(str) {
  const startIndex = str.indexOf('/', 1)
  if(startIndex < 0) return null
  const slice = str.slice(startIndex + 1)
  return decodeURI(slice)
}

export default DeveloperPage