import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import ListSelector from './ListSelector';
// import SettingsSelector from './SettingsSelector';
import FiltersSelector from './FiltersSelector';
import BuildInfo from './BuildInfo';
import getDefaultUserFilters from '../funcs/getDefaultUserFilters'

function LibraryHeader({lists, listsInfo, filtersInfo, buildDate }) {

  const [showInfo, setShowInfo] = useState(false)
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);

  const [showList, setShowList] = useState(false);
  const handleCloseList = () => setShowList(false);
  const handleShowList = () => setShowList(true);
  //const [showSettings, setShowSettings] = useState(false);
  //const handleCloseSettings = () => setShowSettings(false);
  //const handleShowSettings = () => setShowSettings(true);
  const [showFilters, setShowFilters] = useState(false);
  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);
  const handleFiltersReset = () => filtersInfo.setUserFilters(getDefaultUserFilters())

  // <Button onClick={handleShowSettings} variant="outline-secondary" className='m-1' size="sm"><i className="fa-solid fa-sliders"></i></Button>
  // <SettingsSelector listsInfo={listsInfo} show={showSettings} handleClose={handleCloseSettings}/>

  return (
    <Navbar expand="lg" variant="light" bg="light">
      <Container>
        <div className='d-flex'>
          <Button onClick={handleShowInfo} variant="outline-secondary" className='m-1' size="sm"><i className="fa-solid fa-circle-info"></i></Button>
          <Button onClick={handleShowList} variant="outline-secondary" className='m-1' size="sm"><i className="fa-solid fa-list"></i></Button>
        </div>
        <div className='d-flex'>
          <Button onClick={handleShowFilters} variant="outline-secondary" className='m-1' size="sm"><i className="fa-solid fa-filter"></i></Button>
          <Button onClick={handleFiltersReset} variant="outline-secondary" className='m-1' size="sm"><i className="fa-solid fa-broom"></i></Button>
        </div>
      </Container>
      <ListSelector listsInfo={listsInfo} show={showList} handleClose={handleCloseList}/>
      <FiltersSelector listsInfo={listsInfo} show={showFilters} handleClose={handleCloseFilters} filtersInfo={filtersInfo}/>
      <BuildInfo show={showInfo} handleClose={handleCloseInfo} buildDate={buildDate}/>
    </Navbar>
  )
}

export default LibraryHeader