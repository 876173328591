import { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllDetails from "./AllDetails";
import SteamDetails from "./SteamDetails";
import AggregateDetails from "./AggregateDetails"
import ReviewDetails from "./ReviewDetails"

export default function RatingsTabs({ steam, wiki }) {
  const [key, setKey] = useState('all');
  const wikiUrl = `http://en.wikipedia.org/?curid=${wiki.pageId}`

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="all" title="All">
        <AllDetails steam={steam} wiki={wiki} wikiUrl={wikiUrl}/>
      </Tab>
      <Tab eventKey="steam" title="Steam">
        <SteamDetails data={steam}/>
      </Tab>
      <Tab eventKey="aggregate" title="Aggregate">
        <AggregateDetails data={wiki.aggregators} wikiUrl={wikiUrl}/>
      </Tab>
      <Tab eventKey="reviews" title="Reviews">
        <ReviewDetails data={wiki.reviews} wikiUrl={wikiUrl}/>
      </Tab>
    </Tabs>
  );
}
