import React, { useState } from 'react';
import '../css/ListSelector.css'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ListGroup from 'react-bootstrap/ListGroup';

function ListSelector({ listsInfo, show, handleClose }) {

  return (
    <Offcanvas show={show} onHide={handleClose} backdrop={false}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select A List</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <List listsInfo={listsInfo} handleClose={handleClose}/>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function List({ listsInfo, handleClose}) {
  const {lists, listName, setListName} = listsInfo
  const format = [
    ['CONSOLE_ALL'],
    ['CONSOLE_RECENTLY_ADDED', 'CONSOLE_COMING_SOON', 'CONSOLE_LEAVING_SOON'],
    ['CONSOLE_POPULAR', 'CONSOLE_BEST_OF_2022'],
    ['CONSOLE_FAMILY_FRIENDLY'],
    ['CONSOLE_OPTIMISED']
  ]

  const handleClick = e => {
    setListName(e.target.id)
    handleClose()
  }

  const htmlMap = format.map((l,i, arr) => {
    const hr = i < (arr.length - 1) ? <hr key={'hr'+ i}/> : ''
    const items = l.map((e, i) => {
      if(e === listName) return <ListGroup.Item key={'list' + i} as="li" active>{lists[e].title}</ListGroup.Item>
      return <ListGroup.Item id={e} key={'list' + i} as="li" onClick={handleClick}>{lists[e].title}</ListGroup.Item>
    })
    return (
      <div key={i}>
      <ListGroup key={'listg' + i} as="ul">
        {items}
      </ListGroup>
      {hr}
      </div>
    )
  })

  return <div>{htmlMap}</div>
}

export default ListSelector