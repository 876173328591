import React, { useState, useEffect, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import PageHeader from '../comps/PageHeader';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Grid from '../comps/Grid';
import Paginator from '../comps/Paginator';
import getPageItems from '../funcs/getPageItems';

function Search() {
  const [ context ] = useOutletContext();
  const { games } = context
  const options = ['all', 'title', 'developer', 'publisher']
  const [option, setOption] = useState(options[0])
  const [text, setText] = useState('')
  const [results, setResults] = useState(null)
  const description = 'Search for a game, developer or publisher'
  const textInfo = { text, setText}
  const optionsInfo = { option, setOption, options}

  useEffect(() => { 
    if(text.length < 3) setResults(null)
    if(text.length >= 3) {
      const results = searcher({ text, option, games})
      setResults(results)
    } 
  }, [text, option, games])

  let contents
  if(results) {
    if(results.length === 0 ) {
      contents = <NoMatches/>
    } else {
      //const lines = results.map((g, i) => <li key={i}>{g.title}</li>)
      contents = <SearchResults items={results}/>//<ul>{lines}</ul>
    }
  } else {
    contents = ''
  }

  const minCharacters = 3
  const needInputHelp = text.length > 0 && text.length < 3
  const charactersNeeded = minCharacters - text.length
  const inputHelp = needInputHelp ? (<p className='text-center text-white'>
  Keep on typing, we need {charactersNeeded} more character{charactersNeeded > 1 ? 's': ''} for the search.
</p>) : ''

  return (
    <div className='xgp-bg-img flex-container flex-content'>
      <PageHeader title='Search' description={description}/>
      <div className='container flex-container flex-content'>
        <div className='container mt-3'>
          <SearchBar textInfo={textInfo} optionsInfo={optionsInfo}/>
        </div>
        {inputHelp}
        {contents}
      </div>
    </div>
  )
}

function SearchBar({ textInfo, optionsInfo}) {
  const { text, setText} = textInfo
  const { setOption, options} = optionsInfo
  
  const optionsHtml = options.map((e, i) => <option key={i}>{e}</option>)

  const handleSelectChange = e => setOption(e.target.value)
  const handleSearchChange = e => setText(e.target.value)

  // https://blog.logrocket.com/how-to-autofocus-using-react-hooks/#:~:text=Autofocusing%20using%20React%20useRef(),%3D%3E%20%7B%20if%20(emailInput.
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <InputGroup className="mb-3">
      <InputGroup.Text>
        <i className="fas fa-search"></i>
      </InputGroup.Text>
      <Form.Control ref={inputRef} type="text" placeholder="Search" value={text} onChange={handleSearchChange}/>
      
      <Form.Group>
        <Form.Select onChange={handleSelectChange}>
          {optionsHtml}
        </Form.Select>
      </Form.Group>
    </InputGroup>
  )
}

function SearchResults({ items }) {
  const itemsPerPage = 18
  const [page, setPage] = useState(1)
  const gridItems = getPageItems(page, itemsPerPage, items)
  const totalPages = Math.ceil(items.length/itemsPerPage)
  const pageInfo = {page, setPage, totalPages}

  const paginator = items.length > itemsPerPage ? <Paginator pageInfo={pageInfo} totalItems={items.length}/> : ''
  return (
    <div>
      <Grid items={gridItems} pageInfo={pageInfo}/>
      { paginator }
    </div>
  )
}

function searcher({ text, option, games}) {
  const arr = []
  for (const game of games) {
    if(option === 'all' || option === 'title') {
      const includesText = game.title.toUpperCase().includes(text.toUpperCase())
      if(includesText) {
        game.link = `/g/${game.id}`
        arr.push(game)
      }
    }
    if(option === 'all' || option === 'developer') {
      const includesText = game.developer.toUpperCase().includes(text.toUpperCase())
      if(includesText) arr.push({ title: game.developer, link: `/d/${game.developer}`, type: 'd'})
    }
    if(option === 'all' || option === 'publisher') {
      const includesText = game.publisher.toUpperCase().includes(text.toUpperCase())
      if(includesText) arr.push({ title: game.publisher, link: `/p/${game.publisher}`, type: 'p'})
    }
  }
  const results = []
  for (const e of arr) {
    const isInResults = results.find(r => r.title === e.title)
    if(!isInResults) results.push(e)
  }
  return results
}

function NoMatches() {
  return (
    <div className='flex-container flex-content justify-content-center'>
      <h3 className='text-center text-white'>No Matches</h3>
    </div>
  )
}

export default Search