import '../css/AppMainNav.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useHref, useNavigate } from 'react-router-dom'

function MainNavBar() {
  const navigate = useNavigate();

  const navLinks = [
    { name: "Search", href: "/search"},
    { name: "Games", href: "/"},
    { name: "Developers", href: "/developers"},
    { name: "Publishers", href: "/publishers"},
    { name: "About", href: "/about"},
    { name: "Contact", href: "/contact"}
  ]

  const currentHref = useHref()

  const makeLink = (name, href, i) => {
    let className = 'main-nav-link'
    if(currentHref === href) className = className.concat(' main-nav-link-active')
    return <Link  key={i} className={className} to={href}>{name}</Link>
  }

  return (
    <Navbar  collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <span onClick={() => navigate('/')}><i className="fa-brands fa-xbox"></i> Game Pass Ratings</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          </Nav>
          <Nav>
            {navLinks.map((n, i) => makeLink(n.name, n.href, i))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MainNavBar;