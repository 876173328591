import ScoreBadge from "../ScoreBadge"

export default function SteamDetails({data}) {
  let content
  if(data.ratingSteamDb < 0) content = <p>No Data Available</p>
  if(data.ratingSteamDb >= 0) {
    const {totalReviews, ratingSteam, ratingSteamDb, recentReviewsText, url } = data
    const recentScoreText = (()=> {
      if(!recentReviewsText) return 'Unknown'
      const percentIndex = recentReviewsText.indexOf('%')
      const score = recentReviewsText.slice(0, percentIndex)
      const badge = <ScoreBadge score={score} text={score + '%'}/>
      return <>{badge} {recentReviewsText.slice(percentIndex + 1)}</>
    })()
    content = (
      <dl className="row">
        <dt className="col-sm-3">Steam Recent:</dt>
        <dd className="col-sm-9">
          {recentScoreText}
        </dd>
        <dt className="col-sm-3">Steam:</dt>
        <dd className="col-sm-9">
          <a target="_blank" rel="noreferrer" href={url}>
            <ScoreBadge score={ratingSteam} text={ratingSteam.toFixed(2) + '%'}/>
          </a>
          ({totalReviews} reviews)
        </dd>
        <dt className="col-sm-3">SteamDB:</dt>
        <dd className="col-sm-9">
          <ScoreBadge score={ratingSteamDb} text={ratingSteamDb.toFixed(2) + '%'}/>
        </dd>
      </dl>
    )
  }
  return (
    <div>
      { content }
    </div>
  )
}