import '../css/PageHeader.css'

function PageHeader({title, description}) {
  const p = description ? <p className="text-center">{description}</p> : ''
  return (
    <div className='page-header'>
      <h1 className="text-center font-monospace">{title}</h1>
      {p}
    </div>
  )
}

export default PageHeader