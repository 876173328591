
function ScoreBadge({score, text}) {
  const allColors = ["#d7e1ee", "#cbd6e4", "#bfcbdb", "#b3bfd1", "#a4a2a8", "#df8879", "#c86558", "#b04238", "#991f17"]
  let bgColor = null
  if(score < 25) bgColor = allColors[0]
  if(score >= 25) bgColor = allColors[1]
  if(score >= 40) bgColor = allColors[2]
  if(score >= 50) bgColor = allColors[3]
  if(score >= 60) bgColor = allColors[4]
  if(score >= 70) bgColor = allColors[5]
  if(score >= 80) bgColor = allColors[6]
  if(score >= 90) bgColor = allColors[7]
  if(score >= 95) bgColor = allColors[8]
  const style = { backgroundColor: bgColor}
  if(score < 0) text = 'N/A'
  return <span className="badge m-1 text-dark" style={style}>{text}</span>
}

export default ScoreBadge