export default function GameAbout({descriptionLong, image}) {
  const descriptionLongArr = descriptionLong.split('\r\n\r\n')
  const paragraphs = descriptionLongArr.map((d, i) => {
    const smallPs = d.split('\r\n').map((s,i) => <p key={i}>{s}</p>)
    return <div key={i}>{smallPs}</div>
  })
  return (
    <div className='container'>
      <div className="row">
        <div className="col-sm">
          <div className='d-flex h-100 justify-content-center align-items-center'>
            <img className="img-fluid" src={image.uri + '?w=200'} alt=""/>
          </div>
        </div>
        <div className="col-sm">
          {paragraphs}
        </div>
      </div>
    </div>
  )
}